<div class="container">
    <button class="exit" mat-mini-fab matTooltip="Exit" (click)="dialogRef.close()">X</button>
    <div mat-dialog-title>        
        <div class="left">
            <mat-icon class="icon">leaderboard</mat-icon>
        </div>
        <div>
            <h1>{{data.utente.nome}} {{data.utente.cognome}}</h1>
        </div>
    </div>
    <div class="row">        
        <mat-form-field>
            <mat-label><i class="fas fa-search"></i>
                <span>Anno</span>
            </mat-label>
            <mat-select [(value)]="selectedAnno" (valueChange)="categoriaSearch(selectedAnno)">
                <mat-select-trigger *ngIf="selectedAnno">
                    <span>{{selectedAnno}}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let anno of annualita" [value]="anno">
                    <span>{{anno}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="row">
        <div mat-dialog-content class="mat-dialog-content-fixed">
            <div class="row">
                <ngx-charts-line-chart [view]="view" [scheme]="colorScheme" [legend]="legend"
                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis"
                    [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline" [results]="listSpeseGraph"
                    (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
                </ngx-charts-line-chart>
            </div>
        </div>
    </div>


</div>