<div class="outer-div">
    <div class="row">
        <div class="totdiv">
            <mat-card class="totcard">
                <div class="square-icon"  (click)="openModalAndamentoGeneric(listUtentiDetails)">
                    <mat-icon class="icon" inline="true">account_balance</mat-icon>
                </div>
                <mat-card-content>
                    <span class="totale">€ {{sumTotSpese}}</span>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <ng-container *ngIf="listUtentiDetails">
        <mat-grid-list cols="{{listUtentiDetails.length}}">
            <ng-container *ngFor="let utente of listUtentiDetails">
                <div class="profdiv" >
                    <mat-card class="profcard">
                        <img [src]="utente?.photo" class="avatar">
                        <button class="btn-stats" mat-mini-fab matTooltip="Stats" (click)="openModalAndamentoUtente(utente)" color="primary">
                            <mat-icon>trending_up</mat-icon>
                          </button> 
                        <mat-card-subtitle>{{utente.nome}} {{utente.cognome}}</mat-card-subtitle>
                        <mat-card-content>
                            <div class="flex-row">
                                <div class="outlook">
                                    <div class="nrow">
                                        <img class="image1" src="assets/img/dollar.png" />
                                    </div>
                                    <div class="nrow">
                                        <span class="totale">€ {{utente.totSpesa}}</span>
                                    </div>
                                </div>
                                <div [className]="utente.totBilancio>=0 ? 'outlook-up' : 'outlook-down'">
                                    <div class="nrow">
                                        <img class="image2" src="assets/img/balance.png" />
                                    </div>
                                    <div class="nrow">
                                        <span class="totale">€ {{utente.totBilancio}}</span>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </ng-container>
        </mat-grid-list>
    </ng-container>


</div>