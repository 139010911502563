<div mat-dialog-title>
  <div class="left">
    <mat-icon class="orange" *ngIf="!(spesa && spesa.id)">add_shopping_cart</mat-icon>
    <mat-icon color="accent" *ngIf="(spesa && spesa.id)">shopping_cart</mat-icon>
  </div>
  <div class="center">
    <h1>{{this.spesa && this.spesa.id ? "AGGIORNA SPESA" : "NUOVA SPESA"}}</h1>
  </div>
</div>

<form [formGroup]="spesaForm" (ngSubmit)="mergeSpesa()">

  <div class="mat-elevation-z8 mb03">

    
    <div mat-dialog-content>
      
      <div class="row">
        <mat-form-field class="content">
          <mat-label>Partecipanti</mat-label>
          <mat-select formControlName="formSpesaPartecipanti" multiple>
            <mat-option *ngFor="let partecipante of partecipantiList" [value]="partecipante.email">{{partecipante.nome}}
              {{partecipante.cognome}}</mat-option>
          </mat-select>
          <mat-error *ngIf="spesaForm.get('formSpesaPartecipanti').hasError('arrayAtLeastOne')">Seleziona almeno un partecipante</mat-error>
          <mat-error *ngIf="spesaForm.get('formSpesaPartecipanti').hasError('samePartecipante')">Non puoi associare la spesa SOLO a te stesso</mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field appearance="outline" class="content">
          <mat-label>Costo</mat-label>
          <input matInput type="number" formControlName="formSpesaCosto" placeholder="Ex. 0.00" required>
          <mat-icon matSuffix>euro</mat-icon>
          <mat-error>Inserire un valore</mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field appearance="outline" class="content">
          <mat-label>Descrizione</mat-label>
          <textarea matInput formControlName="formSpesaDescrizione" placeholder="Ex. spesa, bolletta" required></textarea>
          <mat-error>Inserire una Descrizione</mat-error>
        </mat-form-field>
      </div>
    
    </div>
  
  </div>

  <div mat-dialog-actions>
    <button class="left" mat-raised-button color="warn" (click)="dialogRef.close()">Annulla</button>
    <button type="submit" class="right" mat-raised-button color="accent">SALVA</button>
  </div>

</form>