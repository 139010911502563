// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyCXE7MsVZYVrmnVn0_Wl5yhxhbXRvUG2qU",
    authDomain: "casa-contabilita.firebaseapp.com",
    databaseURL: "https://casa-contabilita.firebaseio.com",
    projectId: "casa-contabilita",
    storageBucket: "casa-contabilita.appspot.com",
    messagingSenderId: "43449222539",
    appId: "1:43449222539:web:83f83b6e82302bb5251b8e",
    measurementId: "G-XJYFW0WL0Z"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
