import { Injectable } from '@angular/core';
import { SpesaDB } from '../models/spese.model';
import { AngularFirestore } from '@angular/fire/firestore';;
import { DecimalPipe } from '@angular/common';
import { UtenteService } from '../../general/services/utente.service';

import { firestore } from 'firebase';
//import * as fadmin from 'firebase-admin';
import * as data from "./resources/init_DB_4.json";


@Injectable({
  providedIn: 'root'
})
export class SpeseService {

  constructor(private db: AngularFirestore, private pipe: DecimalPipe, private utenteService: UtenteService) {
  }


  insertSpesa(spesa: SpesaDB) {
    return this.db.collection('/spese').add(Object.assign({}, spesa));
  }

  deleteSpesa(spesaId: string) {
    return this.db.doc('/spese/' + spesaId).delete();
  }

  updateSpesa(spesa: SpesaDB) {
    //delete spesa.id;
    return this.db.doc('/spese/' + spesa.id).update(Object.assign({}, spesa));
  }

  getSpesaById(spesaId: string) {
    this.db.collection('/spese').doc(spesaId).get();
  }

  getSpeseList() {
    return this.db.collection('/spese', ref => ref.orderBy('data_insert', 'desc')).snapshotChanges();
  }

  getSpeseListByDate(dateFrom: Date, dateTo: Date) {
    if (dateFrom && dateTo) {
      dateTo = new Date(dateTo.getTime() + (1 * 24 * 60 * 60 * 1000));
      return this.db.collection('/spese', ref => ref.where('data_insert', '>', firestore.Timestamp.fromDate(dateFrom)).where('data_insert', '<', firestore.Timestamp.fromDate(dateTo)).orderBy('data_insert', 'desc')).snapshotChanges();
    } else if (dateFrom) {
      return this.db.collection('/spese', ref => ref.where('data_insert', '>', firestore.Timestamp.fromDate(dateFrom)).orderBy('data_insert', 'desc')).snapshotChanges();
    } else {
      dateTo = new Date(dateTo.getTime() + (1 * 24 * 60 * 60 * 1000));
      return this.db.collection('/spese', ref => ref.where('data_insert', '<', firestore.Timestamp.fromDate(dateTo)).orderBy('data_insert', 'desc')).snapshotChanges();
    }
  }


  initDB() {
    //const serviceAccount = require("./resources/serviceAccountKey.json");
    //import data = require();
    //const collectionKey = "spese"; //name of the collection
    /*
    fadmin.initializeApp({
      credential: fadmin.credential.cert(serviceAccount),
      databaseURL: "https://casa-contabilita.firebaseio.com"
    });
    
    const firestore = fadmin.firestore();
    const settings = { timestampsInSnapshots: true };
    firestore.settings(settings);*/
    if (data && (typeof data === "object")) {      
      Object.keys(data).forEach(docKey => {  
          console.log("ID " + docKey);  
          let listaSpese = data[docKey];
          Object.keys(listaSpese).forEach(key => { 
            console.log("ADD ID " + key);  
            let spesa = listaSpese[key];            
            let pino =  spesa.data_insert;              
            let ciao : Date = new Date(pino*1000);        
            spesa.data_insert = firestore.Timestamp.fromDate(ciao);
            this.insertSpesa(spesa);
           });
       
      });
    }
    
  }



}
