import { firestore } from 'firebase';
import { Utente } from '../../general/models/utente.model';

export class SpesaDB {
    id : string;
    costo : number;
    data_insert : firestore.Timestamp;
    data_update : firestore.Timestamp;
    descrizione : string;
    utente_insert_ref : string;
    utenti_partecipanti_ref : string[];
    old_costo : number;
    old_desc : string;
}

export class Spesa extends SpesaDB {    
    utente : Utente
    partecipanti :  Array<Utente>;
    categoria : string
}

