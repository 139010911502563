<mat-card class="ecard">
  <img mat-card-image src="\assets\img\google.png">
  <mat-card-content>

    <button mat-menu-item (click)="tryGoogleLogin()" class="btn-google">      
      <mat-icon fontSet="fab" fontIcon="fa-google-plus-g" class="size-icon"></mat-icon>
      <span class="title">Login with Google</span>
    </button>

  </mat-card-content>
  <mat-card-actions >
    <span class="center">Accedi tramite la tua utenza Google!</span>
  </mat-card-actions>
</mat-card>