import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(    
    public authService: AuthService,    
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {
  }

  listaspese(){
    this.router.navigate(['/listaspese']);
  }

}
