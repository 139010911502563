<div mat-dialog-title>
    <div class="left">
        <mat-icon class="red">remove_shopping_cart</mat-icon>
    </div>
    <div class="center">
        <h1>ELIMINA SPESA</h1>
    </div>
</div>
<div class="mat-elevation-z8 mb03">
    <div mat-dialog-content>
        <div class="row">
            <mat-label>Partecipanti : </mat-label>
            <div *ngFor="let partecipante of spesa.partecipanti">
                <span>{{partecipante.nome}} {{partecipante.cognome}}</span>
            </div>
        </div>
        <div class="row">
            <mat-label>Costo : </mat-label>
            <span>{{spesa.costo}} €</span>
        </div>
        <div class="row">
            <mat-label>Descrizione : </mat-label>
            <span>{{spesa.descrizione}} </span>
            <p [innerHtml]="utils.checkDescrizione(spesa.descrizione)"></p>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button class="left" mat-raised-button color="warn" (click)="deleteSpesa()">Elimina</button>
    <button class="right" mat-raised-button color="accent" (click)="dialogRef.close()">ANNULLA</button>
</div>