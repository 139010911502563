import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './general/components/login/login.component';
import { AuthGuard } from './general/components/core/auth.guard';
import { UserComponent } from './general/components/user/user.component';
import { UserResolver } from './general/components/user/user.resolver';
import { HomeComponent } from './general/components/home/home.component';
import { ListaspeseComponent } from './contabilita/components/listaspese/listaspese.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserComponent,  resolve: { data: UserResolver}},
  { path: 'listaspese', component: ListaspeseComponent,  resolve: { data: UserResolver}},
  { path: 'home', component: HomeComponent,  resolve: { data: UserResolver}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
