export class Constants {
    static OK = "OK";
    static KO = "KO";
    static INSERT = "INSERITA";
    static UPDATE = "AGGIORNATA";
    static DELETE = "ELIMINATA";

    static MSG_DURATION : number = 3000;

    static MAIL_INSERT : number = 1;
    static MAIL_UPDATE : number = 2;
    static MAIL_DELETE : number = 3;
}