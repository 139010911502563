import { Injectable } from '@angular/core';

const luce = ["iren", "eni", "luce", "energia", "corrente"];
const acqua = ["acqua", "goccia"];
const gas = ["iren", "eni", "gas", "caldaia"];
const animale = ["pappa", "piuma", "cane", "gatto", "cana", "animale"];
const spesa = ["spesa", "lidl", "bennet", "spese", "caffè"];
const internet = ["internet", "telefono", "tiscali"];
const arredamento = ["mobile", "mobili", "ikea", "leroy", "merlin", "self", "arredamento", "arredamenti"];
const auto = ["auto", "fiat"];
const regalo = ["regalo","gift","pacco"];
const moto = ["moto"];
const carburante = ["benzina","diesel"];

@Injectable({
  providedIn: 'root'
})
export class ListaSpeseUtils {


defineCategoria(descrizione: string): string {
    let categoria = "";
    if (luce.some(v => descrizione.toLowerCase().includes(v))) {
      categoria = categoria + " " + 'luce';
    }
    if (gas.some(v => descrizione.toLowerCase().includes(v))) {
      categoria = categoria + " " + 'gas';
    }
    if (acqua.some(v => descrizione.toLowerCase().includes(v))) {
      categoria = categoria + " " + 'acqua';
    }
    if (animale.some(v => descrizione.toLowerCase().includes(v))) {
      categoria = categoria + " " + 'animale';
    }
    if (spesa.some(v => descrizione.toLowerCase().includes(v))) {
      categoria = categoria + " " + 'spesa';
    }
    if (internet.some(v => descrizione.toLowerCase().includes(v))) {
      categoria = categoria + " " + 'internet';
    }
    if (arredamento.some(v => descrizione.toLowerCase().includes(v))) {
      categoria = categoria + " " + 'arredamento';
    }
    if (auto.some(v => descrizione.toLowerCase().includes(v))) {
      categoria = categoria + " " + 'auto';
    } 
    if (regalo.some(v => descrizione.toLowerCase().includes(v))) {
      categoria = categoria + " " + 'regalo';
    }
    if (moto.some(v => descrizione.toLowerCase().includes(v))) {
      categoria = categoria + " " + 'moto';
    } 
    if (carburante.some(v => descrizione.toLowerCase().includes(v))) {
      categoria = categoria + " " + 'carburante';
    } 

    return categoria;
  }


  checkDescrizione(descrizione: string): String {
    let icon: string = '';
    if (luce.some(v => descrizione.toLowerCase().includes(v))) {
      icon = icon + '<i class="fas fa-lightbulb yellow" title="Luce"></i>';
    }
    if (gas.some(v => descrizione.toLowerCase().includes(v))) {
      icon = icon + '<i class="fab fa-hotjar cyan" title="Gas"></i>';
    }
    if (acqua.some(v => descrizione.toLowerCase().includes(v))) {
      icon = icon + '<i class="fas fa-tint blue" title="Acqua"> </i>';
    }
    if (animale.some(v => descrizione.toLowerCase().includes(v))) {
      icon = icon + '<i class="fas fa-bone red" title="Animale"></i>';
    }
    if (spesa.some(v => descrizione.toLowerCase().includes(v))) {
      icon = icon + '<i class="fas fa-cart-plus green" title="Spesa"></i>';
    }
    if (internet.some(v => descrizione.toLowerCase().includes(v))) {
      icon = icon + '<i class="fas fa-wifi purple" title="Internet/Telefono"></i>';
    }
    if (arredamento.some(v => descrizione.toLowerCase().includes(v))) {
      icon = icon + '<i class="fas fa-boxes brown" title="Mobili"></i>';
    }
    if (auto.some(v => descrizione.toLowerCase().includes(v))) {
      icon = icon + '<i class="fas fa-car-side gray" title="Auto"></i>';
    }
    if (regalo.some(v => descrizione.toLowerCase().includes(v))) {
      icon = icon + '<i class="fas fa-gift orange" title="Regalo"></i>';
    }    

    if (moto.some(v => descrizione.toLowerCase().includes(v))) {
      icon = icon + '<i class="fas fa-motorcycle gold" title="Moto"></i>';
    }
    if (carburante.some(v => descrizione.toLowerCase().includes(v))) {
      icon = icon + '<i class="fas fa-gas-pump greenyellow" title="Carburante"></i>';
    } 
    return icon;
  }
}