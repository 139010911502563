import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { UtenteService } from '../../services/utente.service';

@Injectable()
export class AuthService {

  constructor(
    public afAuth: AngularFireAuth,
    private utenteService: UtenteService,
    private httpClient: HttpClient

  ) { }

  /*
   doFacebookLogin(){
     return new Promise<any>((resolve, reject) => {
       let provider = new firebase.auth.FacebookAuthProvider();
       this.afAuth.auth
       .signInWithPopup(provider)
       .then(res => {
         resolve(res);
       }, err => {
         console.log(err);
         reject(err);
       })
     })
   }
 
   doTwitterLogin(){
     return new Promise<any>((resolve, reject) => {
       let provider = new firebase.auth.TwitterAuthProvider();
       this.afAuth.auth
       .signInWithPopup(provider)
       .then(res => {
         resolve(res);
       }, err => {
         console.log(err);
         reject(err);
       })
     })
   }
 */
  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {      
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth
        .signInWithPopup(provider)
        .then(res => {
          this.utenteService.getUtente(res.user.email).subscribe(utenteMap => {          
            if (utenteMap?.length == 0) {
              this.doLogout();
              resolve(null);
            } else {
              this.mergePhoto(res.user.photoURL, res.user.email);
              resolve(res);
            }
          });

        }, err => {
          console.log(err);
          reject(err);
        })
    })
  }

  /*
  doRegister(value){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
      .then(res => {
        resolve(res);
      }, err => reject(err))
    })
  }
*/

  /*
    doLogin(value){
      return new Promise<any>((resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(value.email, value.password)
        .then(res => {
          resolve(res);
        }, err => reject(err))
      })
    }
    */

  doLogout() {
    return new Promise<void>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.auth.signOut();
        resolve();
      }
      else {
        reject();
      }
    });
  }



  async mergePhoto(photoUrl: any, emailUser: string) {
    var filename = emailUser + '.png';
    var exists: boolean = false;

    await firebase.storage().refFromURL('gs://casa-contabilita.appspot.com/utenti/' + filename).getDownloadURL().then(function (url) {
      exists = true;
    }).catch(function (error) {
      exists = false;
    });

    if (!exists) {
      var storageRef = firebase.storage().refFromURL('gs://casa-contabilita.appspot.com/utenti/' + filename);
      this.httpClient.get(photoUrl, { responseType: 'blob' }).subscribe(res => {
        storageRef.put(res).then(function (snapshot) {
          console.log('Uploaded a blob or file! 1');
        });
      });
    }
  }

}
