import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Spesa } from 'src/app/contabilita/models/spese.model';
import { ListaSpeseUtils } from '../listaspese.utils';
import { multi } from './data';

import {
  MultiSeries, Series, DataItem
} from '@swimlane/ngx-charts/lib/models/chart-data.model';



@Component({
  selector: 'app-andamento',
  templateUrl: './andamento.component.html',
  styleUrls: ['./andamento.component.scss']
})
export class AndamentoComponent implements OnInit{

  listSpeseGraph: any[]
   = [
    {
      name: this.data.utente.nome,
      series: []
    }
  ];

  selectedAnno = new Date().getFullYear().toString();
  annualita = [new Date().getFullYear().toString()];


  
  view: any[] = [700, 300];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Data inserimento';
  yAxisLabel: string = 'Spesa (€)';
  timeline: boolean = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor(
    public dialogRef: MatDialogRef<AndamentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    
  }
  ngOnInit(): void {
    this.categoriaSearch(this.selectedAnno);
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }



  categoriaSearch(selected) {

    let filteredSpeseUtente: Spesa[] = this.data.filteredSpese.sort((a, b) => {
      return <any>new Date(a.data_insert.toDate()) - <any>new Date(b.data_insert.toDate()) ;
    });

    this.annualita = filteredSpeseUtente
    .map(item => item.data_insert.toDate().getFullYear().toString())
    .filter((value, index, self) => self.indexOf(value) === index);

    this.listSpeseGraph[0].series = [];
    filteredSpeseUtente
    .filter(fil=> fil.data_insert.toDate().getFullYear().toString()==selected)
    .forEach(element => {
      this.listSpeseGraph[0].series.push(
        {
          'name': element.data_insert.toDate().toLocaleDateString(),
          'value': element.costo
        });
    });

    this.listSpeseGraph = [...this.listSpeseGraph];
  }


}
