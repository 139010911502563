<button mat-fab class="md-fab-manage-spesa" matTooltip="Nuova spesa" (click)="openModalManageSpesa()">
  <mat-icon>add</mat-icon>
</button>

<div class="mat-elevation-z8">
  <app-statistiche></app-statistiche>
</div>

<!--
  <button matSuffix class="remove" mat-stroked-button color="warn"
          (click)="initDB()">INIT DB JSON</button>
  -->


<div class="mat-elevation-z8 mb03" *ngIf="listSpese && listSpese.length>0">
  <div class="rowinit">

    <mat-form-field>
      <mat-label><i class="fas fa-search"></i>
        <span>Categoria</span>
      </mat-label>
      <mat-select [(value)]="selectedCategoria" (valueChange)="categoriaSearch(selectedCategoria)">
        <mat-select-trigger *ngIf="selectedCategoria">
          <i class="{{selectedCategoria.icon}}"></i>
          <span>{{selectedCategoria.label}}</span>
        </mat-select-trigger>
        <mat-option *ngFor="let categoria of categorie" [value]="categoria">
          <i class="{{categoria.icon}}"></i> <span>{{categoria.label}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button *ngIf="selectedCategoria" matSuffix class="remove" mat-stroked-button color="warn"
      (click)="resetSearchCategoria()">X</button>

    <mat-form-field class="cerca">
      <mat-label><i class="far fa-edit"></i><span>Cerca</span></mat-label>
      <input matInput [(ngModel)]="searchText" (keyup)="textSearch()" placeholder="Ex. costo,descrizione">
    </mat-form-field>

  </div>

  <div class="rowinit">

    <mat-form-field>
      <mat-label>Dal</mat-label>
      <input matInput [matDatepicker]="dpFrom" readonly [(ngModel)]="datepickerFrom" (click)="dpFrom.open()">
      <button *ngIf="datepickerFrom" matSuffix class="remove" mat-stroked-button color="warn"
        (click)="resetDatepickerFrom()">X</button>
      <mat-datepicker #dpFrom disabled="false"></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Al</mat-label>
      <input matInput [matDatepicker]="dpTo" readonly [(ngModel)]="datepickerTo" (click)="dpTo.open()">
      <button *ngIf="datepickerTo" matSuffix class="remove" mat-stroked-button color="warn"
        (click)="resetDatepickerTo()">X</button>
      <mat-datepicker #dpTo disabled="false"></mat-datepicker>
    </mat-form-field>

    <button mat-mini-fab (click)="ricercaSpeseByDate()" matTooltip="Ricerca spese per data" style="margin-left: 1rem;">
      <mat-icon>search</mat-icon>
    </button>

    <button *ngIf="resetListSpeseSearch" mat-mini-fab color="warn" (click)="resetListSpese()"
      matTooltip="Reset lista spese" style="margin-left: 1rem;">
      <mat-icon>settings_backup_restore</mat-icon>
    </button>

    <div *ngIf="errorDatepicker">
      <span class="error">(Selezionare almeno una data per effettuare la ricerca)</span>
    </div>

  </div>
</div>


<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="data_insert">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Data inserimento </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.data_insert.toDate().toLocaleString()}}
        <mat-icon class="fssmall" matTooltip="Update : {{row.data_update.toDate().toLocaleString()}}"
          matTooltipClass="update-tooltip" matTooltipPosition="right" *ngIf="row.data_update">update</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="costo">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Costo </mat-header-cell>
      <mat-cell *matCellDef="let row"><span matPrefix>€&nbsp;</span> {{row.costo}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="descrizione">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="wrapper">
          <span [innerHtml]="utils.checkDescrizione(row.descrizione)"></span>
          <span>{{row.descrizione}}</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ute_ins">
      <mat-header-cell *matHeaderCellDef> Utente </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{row.utente?.nome}} {{row.utente?.cognome}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ute_part">
      <mat-header-cell *matHeaderCellDef> Partecipanti </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="wrapper">
          <span class="row" *ngFor="let utente of row.partecipanti">{{utente.nome}} {{utente.cognome}}</span>
        </div>

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="azioni">
      <mat-header-cell *matHeaderCellDef> Azioni </mat-header-cell>
      <mat-cell *matCellDef="let row">

        <button *ngIf="userEmailLogged == row.utente?.email" mat-mini-fab color="accent" class="ml1"
          matTooltip="Modifica spesa" (click)="openModalManageSpesa(row)">
          <mat-icon>edit</mat-icon>
        </button>

        <button *ngIf="userEmailLogged == row.utente?.email" mat-mini-fab color="warn" class="ml1"
          matTooltip="Elimina spesa" (click)="openModalDeleteSpesa(row)">
          <mat-icon>delete</mat-icon>
        </button>

      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

</div>