import { Injectable } from '@angular/core';
import { SpesaDB } from 'src/app/contabilita/models/spese.model';
import { Constants } from '../utils/constants.utils';

import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { UtenteService } from './utente.service';
import { Utente } from '../models/utente.model';
import { UserFirebaseService } from '../components/core/user.service';
import { Router } from '@angular/router';
init("user_w7KKzBSakravOLgftPycK");

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(
    public utenteService: UtenteService,
    public userFirebaseService: UserFirebaseService,
    private router: Router) {

  }



  sendEmail(spesa: SpesaDB, action: number) {
    this.userFirebaseService.getCurrentUser().then(
      res => {
        this.utenteService.getListUtentiByList(spesa.utenti_partecipanti_ref).subscribe(utentiMap => {
          utentiMap.map(
            e => {
              let utente = {
                id: e.payload.doc.id,
                ...e.payload.doc.data() as Utente
              } as Utente;

              let templateParams;
              let templateMail;
              switch (action) {
                case Constants.MAIL_UPDATE: {
                  templateParams = {
                    to_name: utente.nome,
                    to_email: utente.email,
                    from_name: res.displayName,
                    spesa_costo: spesa.old_costo,
                    spesa_desc: spesa.old_desc,
                    new_spesa_costo : spesa.costo,
                    new_spesa_desc : spesa.descrizione,
                    default_name: 'HERMES',
                    url: this.router.url,
                    stato: 'AGGIORNATO',
                    subject: 'Spesa aggiornata',
                    icon : 'spesa_mod.png'
                  };
                  templateMail = 'template_9pgeqhs';
                  break;
                }
                case Constants.MAIL_INSERT: {
                  templateParams = {
                    to_name: utente.nome,
                    to_email: utente.email,
                    from_name: res.displayName,
                    spesa_costo: spesa.costo,
                    spesa_desc: spesa.descrizione,
                    default_name: 'HERMES',
                    url: this.router.url,
                    stato: 'INSERITO',
                    subject: 'Nuova spesa inserita',
                    icon : 'spesa_add.png'
                  };
                  templateMail = 'template_7pa4zyo';
                  break;
                }
                case Constants.MAIL_DELETE: {
                  templateParams = {
                    to_name: utente.nome,
                    to_email: utente.email,
                    from_name: res.displayName,
                    spesa_costo: spesa.costo,
                    spesa_desc: spesa.descrizione,
                    default_name: 'HERMES',
                    url: this.router.url,
                    stato: 'ELIMINATO',
                    subject: 'Spesa eliminata',
                    icon : 'spesa_del.png'
                  };
                  templateMail = 'template_7pa4zyo';
                  break;
                }
                default:
                  break;
              }

              emailjs.send('service_1f6w63r', templateMail, templateParams)
                .then((result: EmailJSResponseStatus) => {
                  console.log(result.text);
                }, (error) => {
                  console.log(error.text);
                });
            });
        });
      });
  }

}
