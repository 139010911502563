<mat-card class="ecard">
  <img mat-card-image src="\assets\img\chart-icon-color.png">
  <mat-card-content>

    <button mat-menu-item routerLink="../listaspese" routerLinkActive="active" class="btn-listaspese">
      <mat-icon fontSet="fas" fontIcon="fa-chart-bar" class="size-icon"></mat-icon>
      <span class="title">Spese</span>
    </button>

  </mat-card-content>
  <mat-card-actions>
    <span class="center">Visualizza l'elenco ed i grafici statistici delle spese inserite.</span>
  </mat-card-actions>
</mat-card>