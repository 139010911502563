import { Component } from '@angular/core';
import { AuthService } from '../core/auth.service'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtenteService } from '../../services/utente.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../utils/constants.utils';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'page-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.scss']
})
export class LoginComponent extends BaseComponent{

  loginForm: FormGroup;
  errorMessage: string = '';

  constructor(
    public authService: AuthService,
    private router: Router,
    private msg : MatSnackBar
  ) {
    super(msg);
  }
  
  tryGoogleLogin(){
    this.authService.doGoogleLogin()
    .then(res => {           
      if (res) {
        this.router.navigate(['/home']);  
      } else {
        this.openMessage(Constants.KO,'Utente non abilitato!!!');
      }
    })
  }

  

}
