
import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Spesa} from '../../models/spese.model';

import { SpeseService } from '../../services/spese.service';


import { UserFirebaseService } from '../../../general/components/core/user.service'
import { UtenteService } from '../../../general/services/utente.service';

import { Utente } from '../../../general/models/utente.model';

import { DocumentChangeAction } from '@angular/fire/firestore';



import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateAdapter } from '@angular/material/core';
import { ListaSpeseUtils } from './listaspese.utils';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageSpesaComponent } from './managespesa/managespesa.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from 'src/app/general/utils/constants.utils';
import { Esito } from '../../models/esito.model';
import { BaseComponent } from 'src/app/general/components/base/base.component';
import { MailService } from 'src/app/general/services/mail.service';

@Component({
  selector: 'app-listaspese',
  templateUrl: './listaspese.component.html',
  styleUrls: ['./listaspese.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListaspeseComponent extends BaseComponent implements OnInit, AfterViewInit {

  //MATERIAL
  displayedColumns: string[] = ['data_insert', 'costo', 'descrizione', 'ute_ins', 'ute_part', 'azioni'];
  dataSource: MatTableDataSource<Spesa>;
  listSpese: Spesa[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  datepickerFrom: Date;
  datepickerTo: Date;
  errorDatepicker: boolean = false;
  resetListSpeseSearch: boolean = false;
 
  categorie = [
    { label: 'Luce', value: 'luce', icon: 'fas fa-lightbulb yellow' },
    { label: 'Gas', value: 'gas', icon: 'fab fa-hotjar cyan' },
    { label: 'Acqua', value: 'acqua', icon: 'fas fa-tint blue' },
    { label: 'Regalo', value: 'regalo', icon: 'fas fa-gift orange' },
    { label: 'Animale', value: 'animale', icon: 'fas fa-bone red' },
    { label: 'Spesa', value: 'spesa', icon: 'fas fa-cart-plus green' },
    { label: 'Internet', value: 'internet', icon: 'fas fa-wifi purple' },
    { label: 'Arredamento', value: 'arredamento', icon: 'fas fa-boxes brown' },
    { label: 'Auto', value: 'auto', icon: 'fas fa-car-side gray' },    
    { label: 'Moto', value: 'moto', icon: 'fas fa-motorcycle gold' },
    { label: 'Carburante', value: 'carburante', icon: 'fas fa-gas-pump greenyellow' }
  ];
  selectedCategoria: { label: string; value: string; icon: string; };

  searchText: string;

  userEmailLogged: string;

  constructor(
    public speseService: SpeseService,
    public utenteService: UtenteService,
    private dateAdapter: DateAdapter<Date>,
    public userFirebaseService: UserFirebaseService,
    public utils: ListaSpeseUtils,
    public manageSpesa: MatDialog,
    private message: MatSnackBar
  ) {
    super(message);

    this.userFirebaseService.getCurrentUser().then(
      res => {
        this.userEmailLogged = res.email;
      }
    );

    console.log("CONSTRUCTOR");
    this.dateAdapter.setLocale('it-it');
    this.mappingListSpese();

  }

  ngOnInit(): void {
    console.log("ON INIT");
  }

  ngAfterViewInit(): void {
    console.log("AFTER INIT");
  }


  textSearch() {
    this.selectedCategoria = null;
    this.dataSource.filterPredicate = function (data, filter: string = this.searchText.trim().toLowerCase()): boolean {
      return data.descrizione.trim().toLowerCase().includes(filter) || data.costo.toString().trim().toLowerCase().includes(filter);
    };
    this.dataSource.filter = this.searchText.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  categoriaSearch(selected: { label: string; value: string; icon: string; }) {
    this.searchText = null;
    this.dataSource.filterPredicate = function (data, filter: string = selected.value.trim().toLowerCase()): boolean {
      return data.categoria.trim().toLowerCase().includes(filter);
    };

    this.dataSource.filter = selected.value.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  ricercaSpeseByDate() {
    if (this.datepickerFrom || this.datepickerTo) {
      this.mappingListSpese(
        this.speseService.getSpeseListByDate(this.datepickerFrom, this.datepickerTo));
      this.errorDatepicker = false;
      this.resetListSpeseSearch = true;
    } else {
      this.errorDatepicker = true;
    }

  }

  resetDatepickerFrom() {
    this.datepickerFrom = null;
  }
  resetDatepickerTo() {
    this.datepickerTo = null;
  }

  resetListSpese() {
    this.mappingListSpese();
    this.resetListSpeseSearch = false;
    this.datepickerTo = null;
    this.datepickerFrom = null;
  }

  resetSearchCategoria() {
    this.selectedCategoria = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  mappingListSpese(array: Observable<DocumentChangeAction<unknown>[]> = this.speseService.getSpeseList()) {
    array.subscribe(listSpeseMap => {
      this.listSpese = listSpeseMap.map(data => {
        return {
          id: data.payload.doc.id,
          ...data.payload.doc.data() as Spesa
        } as Spesa;
      })

      this.listSpese.forEach(spesa => {

        //CATEGORIZZO
        spesa.categoria = this.utils.defineCategoria(spesa.descrizione);

        //RECUPERO UTENTE INSERT
        spesa.utente = new Utente();
        this.utenteService.getUtente(spesa.utente_insert_ref).subscribe(utenteMap => {
          spesa.utente = utenteMap.map(e => {
            return {
              id: e.payload.doc.id,
              ...e.payload.doc.data() as Utente
            } as Utente;
          })[0];

          //RECUPERO UTENTI PARTECIPANTI
          spesa.partecipanti = new Array<Utente>();
          spesa.utenti_partecipanti_ref.forEach(utenteRef => {
            this.utenteService.getUtente(utenteRef).subscribe(utenteMap => {
              spesa.partecipanti.push(utenteMap.map(e => {
                return {
                  id: e.payload.doc.id,
                  ...e.payload.doc.data() as Utente
                } as Utente;
              })[0]
              );
            });
          });
        });
      });

      this.dataSource = new MatTableDataSource(this.listSpese);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });

  }

  openModalManageSpesa(spesa : Spesa = new Spesa): void {   
    const dialogRef = this.manageSpesa.open(ManageSpesaComponent, {
      width: '100%',
      data : spesa
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.openMessage(res.codesito, "SPESA "+ res.operazione +" CON " + ( res.codesito == Constants.OK ? "SUCCESSO" : "ERRORE" ));
      }      
    });
   
  }

  openModalDeleteSpesa(spesa : Spesa): void {   
    const dialogRef = this.manageSpesa.open(DeleteSpesaModal, {
      width: '100%',
      data : spesa
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.openMessage(res.codesito, "SPESA "+ res.operazione +" CON " + ( res.codesito == Constants.OK ? "SUCCESSO" : "ERRORE" ));
      }      
    });
   
  }

  initDB() {
    this.speseService.initDB();
  }
} 


@Component({
  selector: 'deletespesa',
  templateUrl: 'deletespesamodal.html',
  styleUrls: ['./managespesa/managespesa.component.scss']
})
export class DeleteSpesaModal {

  constructor(
    public dialogRef: MatDialogRef<DeleteSpesaModal>,
    @Inject(MAT_DIALOG_DATA) public spesa: Spesa,
    public speseService: SpeseService,
    public utils: ListaSpeseUtils,    
    private mail : MailService
    ) {}

    deleteSpesa() {
      let esito = new Esito(Constants.DELETE);
      this.speseService.deleteSpesa(this.spesa.id).then(
        res => {
          esito.codesito = Constants.OK;
          this.mail.sendEmail(this.spesa, Constants.MAIL_DELETE);
          this.dialogRef.close(esito);
        }
      ).catch(
        error => {
          console.error("ERRORE IN ELIMINAZIONE SPESA : " + error);
          esito.codesito = Constants.KO;
          this.dialogRef.close(esito);
        }
      );    
    }

  
  

}