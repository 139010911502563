<div class="container-fluid">
  <div class="card-group">
      <!--
      <mdb-card>

      
        <div class="view view-cascade overlay waves-light" style="width: 20rem;">
          <img [src]="user.image" class="rounded-circle p-4" style="width: 10rem;">
          
        </div>
      

        <mdb-card-body cascade="true" class="text-center bg-light">
      
          <h5 class="blue-text">
            <div class="mask rgba-white-slight">
              <h4 class="card-title">{{user.name}}</h4>
              <h4 class="card-title">{{user.email}}</h4>
              <h4 class="card-title">{{user.phoneNumber}}</h4>

            </div>
          </h5>

          <mdb-card-text>
            Visualizza l'elenco ed i grafici statistici delle spese inserite.
          </mdb-card-text>

        </mdb-card-body>
        

      </mdb-card>
      -->
  </div>
</div>