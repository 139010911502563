import { Component, OnInit } from '@angular/core';
import { SpeseService } from 'src/app/contabilita/services/spese.service';
import { UtenteService } from 'src/app/general/services/utente.service';
import { Spesa } from 'src/app/contabilita/models/spese.model';
import { Utente } from 'src/app/general/models/utente.model';
import * as firebase from 'firebase/app';
import { MatDialog } from '@angular/material/dialog';
import { AndamentoComponent } from '../andamento/andamento.component';
import { AndamentoGenericComponent } from '../andamento-generic/andamento-generic.component';

@Component({
  selector: 'app-statistiche',
  templateUrl: './statistiche.component.html',
  styleUrls: ['./statistiche.component.scss']
})
export class StatisticheComponent implements OnInit {

  sumTotSpese: number = 0;
  listSpese: Spesa[];
  listUtentiDetails: Utente[];

  constructor(
    public speseService: SpeseService,
    public utenteService: UtenteService,
    public andamentoDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.listUtentiDetails = [];
    this.listSpese = [];

    this.speseService.getSpeseList().subscribe(listSpeseMap => {
     
      this.listSpese = listSpeseMap.map(data => {
        return {
          id: data.payload.doc.id,
          ...data.payload.doc.data() as Spesa
        } as Spesa;
      });

      console.log("AAAAAAAAAAAA");

      this.sumTotSpese = 0;
      this.listSpese.forEach(spesa => {
        this.sumTotSpese = this.sumTotSpese + Number(spesa.costo);
      });
      this.sumTotSpese = Number(this.sumTotSpese.toFixed(2));

      if (this.listSpese) {
        this.listUtentiDetails = [];
        this.calcolaPersone();
      }

    });
  }

  calcolaPersone() {
    this.utenteService.getListUtenti().subscribe(utenteMap => {
      if (utenteMap) {
        utenteMap.map(
          e => {

            let utente = {
              id: e.payload.doc.id,
              ...e.payload.doc.data() as Utente
            } as Utente;

            firebase.storage().refFromURL('gs://casa-contabilita.appspot.com/utenti/' + utente.email + '.png').getDownloadURL().then(url => { utente.photo = url; }).catch(error => { utente.photo = '/assets/img/no_img.png'; }); utente.totSpesa = 0;

            utente.totSpesa = 0;
            utente.totBilancio = 0;

            this.listSpese.filter(fil => fil.utente_insert_ref == utente.email).forEach(spesa => {
              utente.totSpesa = utente.totSpesa + Number(spesa.costo);
            });
            utente.totSpesa = Number(utente.totSpesa.toFixed(2));


            this.listSpese.filter(fil => fil.utenti_partecipanti_ref.includes(utente.email)).forEach(spesa => {
              utente.totBilancio = utente.totBilancio + (Number(spesa.costo) / spesa.utenti_partecipanti_ref.length);
            });
            utente.totBilancio = Number(utente.totBilancio.toFixed(2));

            utente.totBilancio = utente.totSpesa - utente.totBilancio;
            utente.totBilancio = Number(utente.totBilancio.toFixed(2));
            console.log("UTENTE : " + utente.nome);
            this.listUtentiDetails.push(utente);
          }
        );
      }
    });
  }


  openModalAndamentoUtente(utente : Utente): void {   
    let filteredSpese = this.listSpese.filter(fil => fil.utente_insert_ref == utente.email);
    const dialogRef = this.andamentoDialog.open(AndamentoComponent, {
      width: '100%',
      //height : '99%',
      data : {filteredSpese,utente}
    });   
  }

  openModalAndamentoGeneric(utenti : Utente[]): void {   
    let filteredSpese = this.listSpese;
    const dialogRef = this.andamentoDialog.open(AndamentoGenericComponent, {
      width: '100%',
      height : '90%',
      data : {filteredSpese,utenti}
    });   
  }
}
