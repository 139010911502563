import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Utente } from '../models/utente.model';

@Injectable({
  providedIn: 'root'
})
export class UtenteService {

  utente: Utente;

  constructor(private db: AngularFirestore) {
    
  }

  getUtente(emailUsr : string) {
    return this.db.collection('/utenti', ref => ref.where('email', '==', emailUsr)).snapshotChanges();
  }

  getListUtenti() {
    return this.db.collection('/utenti').snapshotChanges();
  }

  getListUtentiByList(emailList : string []) {
    return this.db.collection('/utenti', ref => ref.where('email', 'in', emailList)).snapshotChanges();
  }
 

}
