import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpesaDB, Spesa } from 'src/app/contabilita/models/spese.model';
import { firestore } from 'firebase';
import { UserFirebaseService } from 'src/app/general/components/core/user.service';
import { SpeseService } from 'src/app/contabilita/services/spese.service';
import { Esito } from 'src/app/contabilita/models/esito.model';
import { Constants } from 'src/app/general/utils/constants.utils';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, Validator, AbstractControl } from '@angular/forms';
import { UtenteService } from 'src/app/general/services/utente.service';
import { Utente } from 'src/app/general/models/utente.model';
import { MailService } from 'src/app/general/services/mail.service';

@Component({
  selector: 'app-managespesa',
  templateUrl: './managespesa.component.html',
  styleUrls: ['./managespesa.component.scss']
})
export class ManageSpesaComponent implements OnInit {

  userEmailLogged: string;

  spesaForm: FormGroup;

  defaultPartecipanti: string[] = [];
  partecipantiList: Utente[];
  spesaTmp : SpesaDB;
  
  constructor(
    public dialogRef: MatDialogRef<ManageSpesaComponent>,
    @Inject(MAT_DIALOG_DATA) public spesa: Spesa,
    public userFirebaseService: UserFirebaseService,
    public speseService: SpeseService,
    public utenteService: UtenteService,
    private formBuilder: FormBuilder,
    private mail : MailService
  ) {

    this.userFirebaseService.getCurrentUser().then(
      res => {
        this.userEmailLogged = res.email;
      });


    this.utenteService.getListUtenti().subscribe(
      utenteMap => {
        this.partecipantiList =
          utenteMap.map(
            e => {
              return {
                id: e.payload.doc.id,
                ...e.payload.doc.data() as Utente
              } as Utente;
            }
          );

        this.partecipantiList.forEach(element => {
          this.defaultPartecipanti.push(element.email);
        });

      });
  }



  ngOnInit() {
    
    this.spesaTmp = this.spesa;
    this.spesaForm = this.formBuilder.group({
      formSpesaPartecipanti: new FormControl(this.spesa.utenti_partecipanti_ref && this.spesa.utenti_partecipanti_ref.length > 0 ? this.spesa.utenti_partecipanti_ref : this.defaultPartecipanti),
      formSpesaCosto: new FormControl(this.spesa.costo, [Validators.required]),
      formSpesaDescrizione: new FormControl(this.spesa.descrizione, [Validators.required])
    }
    );
  }

  validateArrayAtLeastOne(c: AbstractControl) {
    if (c.value.length == 0) {
      c.setErrors({arrayAtLeastOne: true});
    }
  }

  validateSamePartecipante(c: AbstractControl) {
    if (c.value.length == 1 && c.value[0] == this.userEmailLogged) {
      c.setErrors({samePartecipante: true});
    }
  }


  mergeSpesa() {
    if (this.spesa && this.spesa.id) {
      this.aggiornaSpesa();
    } else {
      this.inserisciSpesa();
      
    }
  }

  aggiornaSpesa() {

    this.validateArrayAtLeastOne(this.spesaForm.get('formSpesaPartecipanti'));
    this.validateSamePartecipante(this.spesaForm.get('formSpesaPartecipanti'));

    if (this.spesaForm.invalid) {
      return;
    }

    console.log(this.spesaForm.get('formSpesaPartecipanti').value);
    console.log(this.spesaForm.get('formSpesaCosto').value);
    console.log(this.spesaForm.get('formSpesaDescrizione').value);

    let esito = new Esito(Constants.UPDATE);
    console.log("AGGIORNO SPESA " + this.spesa.id);
    let spesaDB = new SpesaDB();
    
    spesaDB.id = this.spesa.id;
    spesaDB.costo = this.spesaForm.get('formSpesaCosto').value;
    spesaDB.old_costo = this.spesaTmp.costo;
    spesaDB.old_desc = this.spesaTmp.descrizione;
    spesaDB.data_update = firestore.Timestamp.now();
    spesaDB.descrizione = this.spesaForm.get('formSpesaDescrizione').value;
    spesaDB.utenti_partecipanti_ref = this.spesaForm.get('formSpesaPartecipanti').value;
    this.speseService.updateSpesa(spesaDB).then(
      res => {
        esito.codesito = Constants.OK;
        this.mail.sendEmail(spesaDB, Constants.MAIL_UPDATE);
        this.dialogRef.close(esito);
      }
    ).catch(
      error => {
        console.error("ERRORE IN AGGIORNAMENTO SPESA : " + error);
        esito.codesito = Constants.KO;
        this.dialogRef.close(esito);
      }
    );
  }

  inserisciSpesa() {

    this.validateArrayAtLeastOne(this.spesaForm.get('formSpesaPartecipanti'));
    this.validateSamePartecipante(this.spesaForm.get('formSpesaPartecipanti'));

    if (this.spesaForm.invalid) {
      return;
    }

    console.log(this.spesaForm.get('formSpesaPartecipanti').value);
    console.log(this.spesaForm.get('formSpesaCosto').value);
    console.log(this.spesaForm.get('formSpesaDescrizione').value);

    let esito = new Esito(Constants.INSERT);
    console.log("INSERISCO SPESA");
    let spesaDB = new SpesaDB();
    spesaDB.costo = this.spesaForm.get('formSpesaCosto').value;
    spesaDB.data_insert = firestore.Timestamp.now();
    spesaDB.descrizione = this.spesaForm.get('formSpesaDescrizione').value;
    spesaDB.utente_insert_ref = this.userEmailLogged;
    spesaDB.utenti_partecipanti_ref = this.spesaForm.get('formSpesaPartecipanti').value;
    this.speseService.insertSpesa(spesaDB).then(
      res => {
        console.log("SPESA INSERITA CON SUCCESSO : " + res.id);
        esito.codesito = Constants.OK;
        this.mail.sendEmail(spesaDB, Constants.MAIL_INSERT);
        this.dialogRef.close(esito);
      }
    ).catch(error => {
      console.error("ERRORE IN INSERIMENTO SPESA : " + error);
      esito.codesito = Constants.KO;
      this.dialogRef.close(esito);
    });

  }  

}
