<mat-toolbar color="primary">
  <mat-toolbar-row>
    
    <button mat-menu-item routerLink="home" routerLinkActive="active" style="width: fit-content; padding-left: 0px;" >
      <img src="\assets\img\logo.png" style="width: 9rem">
    </button>
   
    <button mat-stroked-button class="menu-item" routerLink="listaspese" routerLinkActive="active"  *ngIf='isUserLoggedIn'>Contabilità</button>

    <span class="fill-remaining-space"></span>
    <span class="align-center"></span>
    <!--
    <button mat-button>About</button>
    <button mat-button>Contact</button>
    -->
    <img [src]="user?.image" class="avatar" *ngIf='isUserLoggedIn'>
    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf='isUserLoggedIn'>
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="user" routerLinkActive="active">
        <mat-icon>person_outline</mat-icon>
        <span>Profilo</span>
      </button>
      <button mat-menu-item (click)="logout()" class="red-font">
        <mat-icon class="red-font">exit_to_app</mat-icon>
        <span>Log out</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>