import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../utils/constants.utils';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent  {

  constructor(private messages : MatSnackBar
    ) {
      
     }

  openMessage(esito : string, message: string) {    
    this.messages.open(message, null, { 
      duration: Constants.MSG_DURATION,
      panelClass : esito
    });
  }


}
