import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-andamento-generic',
  templateUrl: './andamento-generic.component.html',
  styleUrls: ['./andamento-generic.component.scss']
})
export class AndamentoGenericComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
