import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FirebaseUserModel } from '../core/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isUserLoggedIn : boolean;
  user: FirebaseUserModel;

  constructor(
    public authService: AuthService,    
    private location : Location
      ) { 
    
     this.authService.afAuth.authState.subscribe(res => {
      if (res && res.uid) {
        console.log("LOGGED");
        this.isUserLoggedIn = true;
        this.user = new FirebaseUserModel();
        this.user.image = res.photoURL;
        this.user.name = res.displayName;
        this.user.email = res.email;
        this.user.phoneNumber = res.phoneNumber;
        this.user.provider = res.providerData[0].providerId;          
      } else {
        console.log("NOT LOGGED");
        this.user = null;
        this.isUserLoggedIn = false;
      }
    })
  }

  ngOnInit(): void {
  }

  logout(){
    this.authService.doLogout()
    .then((res) => {
      this.isUserLoggedIn = false;
      this.location.back();
    }, (error) => {
      console.log("Logout error", error);
    });
  }
  
}